import React from 'react'

import './header.css'

import CTA from './CTA'
import HeaderSocial from './HeaderSocials'

import ME from '../../assets/section_header/me.png'

const Header = () => {
  return (
    <header id='header'>
      <div className="container header__container">
        <h1>Jérôme GALLEGO</h1>
        <h5 className="text-light">Fullstack Freelance Developer</h5>
        <CTA />
        <HeaderSocial />

        <div className="me">
          <img src={ME} alt="me" />
        </div>

        <a href="#contact" className='scroll__down'>Scroll Down</a>
      </div>
    </header>
  )
}

export default Header
