import React from 'react'
import {BsLinkedin} from 'react-icons/bs'
import {FaGithub} from 'react-icons/fa'

const HeaderSocials = () => {
  return (
    <div className='header__socials'>
        <a 
          href="https://www.linkedin.com/in/jerome-gallego-b42619207/" 
          target="_blank" 
          rel="noreferrer"
        ><BsLinkedin/></a>
        <a 
          href="https://github.com/jeromegallego68" 
          target="_blank" 
          rel="noreferrer"
        ><FaGithub/></a>
    </div>
  )
}

export default HeaderSocials