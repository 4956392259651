import React, { useState } from 'react'

import './testimonials.css'

import AVTR1_LOGO from '../../assets/section_testimonials/testimonials1/logo.png'
import AVTR1_DOCUMENT from '../../assets/section_testimonials/testimonials1/document.pdf'

import AVTR2_LOGO from '../../assets/section_testimonials/testimonials2/logo.png'
import AVTR2_DOCUMENT from '../../assets/section_testimonials/testimonials2/document.pdf'

import AVTR3_LOGO from '../../assets/section_testimonials/testimonials3/logo.png'

import FRENCH_FLAG from '../../assets/section_testimonials/countries/french.png'
import ENGLISH_FLAG from '../../assets/section_testimonials/countries/english.png'

// import Swiper core and required modules
import { Pagination } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';



const data = [
  {
    avatar: AVTR3_LOGO,
    name: 'Alessandro Crea From ATONEO',
    review: {
      fr: "Nous avons de nombreuses collaborations à notre actif avec Mr Gallego. Il est toujours investi, consciencieux et très structuré dans ses actions.<br><br>Il a de multiples compétences sur divers champs d'actions (Front-end, Back-end et même sur de l'infrastructure server), ce qui fait de lui quelqu'un de très polyvalent.<br><br>Nous recommandons donc fortement Mr Gallego pour son professionnalisme et ses aptitudes.",
      en: "We have many collaborations to our credit with Mr Gallego. He is always invested, conscientious and very structured in his actions.<br><br>He has multiple skills in various fields of action (Front-end, Back-end and even on server infrastructure), which makes him very versatile.<br><br>We therefore strongly recommend Mr. Gallego for his professionalism and skills."
    }
  },
  {
    avatar: AVTR2_LOGO,
    name: 'Guillaume LEFEBVRE From SIELBLEU',
    review: {
      fr: "Jérôme a su s’adapter rapidement dans un nouvel environnement comme le nôtre afin d’être pleinement autonome sur son poste. Il s’est investi avec un grand professionnalisme dans l’ensemble des missions qui lui ont été confiées. <br><br> Curieux, responsable, très impliqué et doté d’un esprit critique et de synthèse, jérôme est un moteur qui aime les challenges et s’investir dans de nouveaux projets. Doté d’un fort esprit d’équipe, il a également toujours su travailler en parfaite autonomie et responsabilité tout au long de ses missions.",
      en: "Jérôme was able to adapt quickly in a new environment like ours in order to be fully independent in his position. He invested himself with great professionalism in all the missions entrusted to him. <br><br> Curious, responsible, very involved and endowed with a critical and synthetic spirit, Jérôme is a driving force who loves challenges and getting involved in new projects. Endowed with a strong team spirit, he has also always known how to work in perfect autonomy and responsibility throughout his missions."
    },
    document: AVTR2_DOCUMENT,
  },
  {
    avatar: AVTR1_LOGO,
    name: 'Morgane GIROD From VINATIS',
    review: {
      fr: "Au sein de notre entreprise, Monsieur Gallego a ainsi acquis de solides compétences en gestion des tâches supports quotidiennes (résolution de bugs) et approfondis ces aptitudes au développement Front (Html/JQuery/CSS). <br><br> Nous souhaitions remercier Mr Gallego de son professionnalisme et de son implication dans son travail et vous recommandons ses services qui seront certainement un atout appréciable dans votre entreprise.",
      en: "Within our company, Mr. Gallego has thus acquired solid skills in managing daily support tasks (bug solving) and deepened his Front development skills (Html/JQuery/CSS). <br><br> We would like to thank Mr. Gallego for his professionalism and his involvement in his work and recommend his services which will certainly be a valuable asset in your company."
    },
    document: AVTR1_DOCUMENT,
  }
]


const Testimonials = () => {
  const [lang, setLang] = useState('fr')

  return (
    <section id='testimonials'>
      <h5>Review from clients</h5>
      <h2>Testimonials</h2>

      <Swiper className="container testimonials__container" 
        // install Swiper modules
        modules={[Pagination]} 
        spaceBetween={40}
        slidesPerView={1}
        pagination={{ clickable: true }}
      >
        <div class="translation__buttons btn-group" role="group">
          <button class={`btn ${lang === 'fr' ? 'btn-primary' : ''}`} onClick={() => setLang('fr')}>
            <img src={FRENCH_FLAG} alt={"french flag"}/>
          </button>
          <button class={`btn ${lang === 'en' ? 'btn-primary' : ''}`}  onClick={() => setLang('en')}>
            <img src={ENGLISH_FLAG} alt={"english flag"}/>
          </button>
        </div>
        {data.map((elem, index) => {
          const {
            name, 
            review,
            avatar,
            document
          } = elem

          return (
            <SwiperSlide key={index} className="testimonial">
              <div className="client__avatar">
                <img src={avatar} alt={`avatar-${index}`}/>
              </div>
              <h5 className='client__name'>{name}</h5>
              {/* eslint-disable-next-line react/no-danger-with-children */}
              <small className='client__review' dangerouslySetInnerHTML={{__html: review[lang]}}></small>
              {document && <div className='client__document'>
                <a href={document} download className='btn'>Download recommendation letter</a>
              </div>}
            </SwiperSlide>
          )
        })}
      </Swiper>
    </section>
  )
}

export default Testimonials